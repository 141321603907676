import { requestByGet, requestByPost } from '@/http'

import { MD5, SHA1 } from 'crypto-js'

const prefix = '/product/'
const getApiName = (name: string): string => prefix + name

export const getProducts = (data?: any, other?: {}) => {
  const api = 'list'

  // if (data && data['sort'] === 'monthly_sales_DESC') {
  //   api = 'list2'
  // }

  const fn = JSON.stringify(data).length > 1024 ? requestByPost : requestByGet

  const md5_key = '0617074f8ba574db341b7f82f477a15a'
  const sha1_key = 'Fj|uU920fsJ19fk1610699564624'
  let str = ''
  Object.keys(data)
    .sort()
    .forEach(key => {
      str += key + data[key]
    })

  data.sign = MD5(
    MD5(str + md5_key).toString() + SHA1(str + sha1_key).toString() + md5_key
  ).toString()

  return fn(getApiName(api), data, other)
}

export const getProductSummary = (data?: {}) => {
  return requestByGet(getApiName('summary'), data)
}

export const getProductCategory = (data?: {}) => {
  return requestByGet(getApiName('category'), data)
}

export const getProductGatherChannel = (data?: {}, other?: {}) => {
  return requestByGet('/promotion/promotionchannel', data, other)
}
