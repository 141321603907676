var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category-bar-wrap"},[_c('section',{staticClass:"update-real-time g-color-desc"},[_c('img',{attrs:{"src":require("@viewsImg/products/new.png"),"alt":""}}),_c('span',{staticClass:"count-wrap"},[_vm._v(" 实时上新："),_c('span',{staticClass:"count"},[_vm._v(_vm._s(_vm.updateRealTimeCount))]),_vm._v(" 条 ")])]),_c('ul',{staticClass:"category-bar g-bgc-white"},_vm._l((_vm.categoryBar),function(item){return _c('li',{key:item.type,staticClass:"g-df-vc wrap g-bs-bb"},[_c('span',{staticClass:"title g-color-desc g-por"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"content",class:item.type},[(item.type === 'category')?[_c('Tab',{staticClass:"wrap",attrs:{"list":item.list,"active":item.active,"params":{
              item: item,
              activeKey: 'active',
              emitType: item.emitType,
              listKey: 'list'
            }},on:{"change":_vm.change}})]:(item.type === 'sort')?[_c('div',{staticClass:"g-df-vc-hsb"},[_c('Tab',{staticClass:"sort-tab",attrs:{"list":item.list,"active":item.active,"force":item.force,"params":{
                item: item,
                activeKey: 'active',
                emitType: item.emitType,
                listKey: 'list',
                iconSort: true
              }},on:{"change":_vm.change}}),_c('Tab',{staticClass:"checked-tab",attrs:{"list":item.checkedList,"active":item.checkedActive,"params":{
                item: item,
                activeKey: 'checkedActive',
                emitType: item.emitTypeByChecked,
                listKey: 'checkedList'
              }},on:{"change":_vm.change}})],1)]:_vm._e()],2)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }